import * as _ from "lodash";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import * as countryFile from "./country.csv";
import * as timezoneFile from "./timezone.csv";

dayjs.extend(utc);
dayjs.extend(timezone);

interface TimeZone {
  name: string;
  countryCode: string;
}

interface Country {
  name: string;
  code: string;
}

let countries: Country[];
let timezones: TimeZone[];

exec();

function exec() {
  console.log("Starting timesink");
  setNow();
  loadTimeZones(timezoneFile);
  loadCountries(countryFile);
  populateCountries();
}

function setNow() {
  const now = dayjs();

  const date = now.format("YYYY-MM-DD");
  const eventdate = document.getElementById("eventdate") as HTMLInputElement;
  eventdate.value = date;

  const time = now.format("HH:mm");
  const eventtime = document.getElementById("eventtime") as HTMLInputElement;
  eventtime.value = time;

  const eventplace = document.getElementById("eventplace") as HTMLInputElement;
  const localtimezone = dayjs.tz.guess();
  const tokens = localtimezone.split("/");
  const place = tokens.length > 1 ? tokens[1] : localtimezone;
  eventplace.value = place;
}

function loadTimeZones(rawCsvFile: any) {
  const csvFile = rawCsvFile as string;

  timezones = csvFile.split("\n").map(line => {
    line = line.trim();
    const values = line.split(",");

    return {
      name: values[0],
      countryCode: values[1],
    }

  });
}

function loadCountries(rawCsvFile: any) {
  const csvFile = rawCsvFile as string;
  countries = csvFile.split("\n")
    .map(line => {
      line = line.trim();
      let codeValue = "";
      let nameValue = "";
      const commaPos = line.indexOf(",");

      if (commaPos !== -1) {
        codeValue = line.substring(0, commaPos);
        nameValue = line.substring(commaPos + 1, line.length);
      }

      return {
        code: codeValue,
        name: nameValue,
      };
    })
    .filter(country => country.code); // Filter out falsy values
}

function populateCountries() {
  const countriesHtml = countries
    .map(country => `<option value="${country.code}">${country.name}</option>`)
    .join(" ");

  const selectCountry = document.getElementById("selectCountry") as HTMLSelectElement;
  selectCountry.innerHTML = countriesHtml;

  selectCountry.addEventListener("change", event => {
    populateTimeZones(selectCountry.value);
  });
}

function populateTimeZones(countryCode: string) {
  const countryZones = timezones.filter(tz => tz.countryCode === countryCode).map(tz => tz.name);
  console.log(countryZones);

  const timeZonesHtml = countryZones
    .map(zone => `<option value="${zone}">${zone}</option>`)
    .join(" ");

    const selectTimeZone = document.getElementById("selectTimeZone") as HTMLSelectElement;
    selectTimeZone.innerHTML = timeZonesHtml;
}